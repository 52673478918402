
import {
  CSSProperties,
  ComponentType,
  HTMLAttributes,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { forwardRef } from "./utils/with-display-name";
import { ResizeObserver, Size } from "./resize-observer";
import { useResizeObserver } from "usehooks-ts";

export interface DeviceViewProps extends HTMLAttributes<HTMLDivElement> {
  width: number;

  height: number;

  BottomElement?: ComponentType<{
    className: string;
    style: CSSProperties;
    children: ReactNode;
  }>;

  children?: ReactNode;
}

export interface DeviceViewRef {
  enterFullscreen(): void;
}


export const DeviceView = forwardRef<DeviceViewRef>("DeviceView")(
  (
    { width, height, BottomElement, children, ...props }: DeviceViewProps,
    ref
  ) => {

    // const [containerSize, setContainerSize] = useState<Size>({

    //   width: 0,
    //   height: 0,
    // });
    // const [bottomSize, setBottomSize] = useState<Size>({
    //   width: 0,
    //   height: 0,
    // });

    // Container size minus bottom element size
    // const usableSize = useMemo(
    //   () => ({
    //     width: containerSize.width,
    //     height: containerSize.height - bottomSize.height,
    //   }),
    //   [containerSize, bottomSize]
    // );

    // Compute sizes after scaling
    // const childrenStyle = useMemo(() => {
    //   let scale: number;
    //   let childrenWidth: number;
    //   let childrenHeight: number;
    //   let childrenTop: number;
    //   let childrenLeft: number;

    //   // console.log("width: ", width);
    //   // console.log("height: ", height);
    //   // console.log("usableSize: ", usableSize);

    //   if (width === 0 || usableSize.width === 0) {
    //     scale = 1;
    //     childrenWidth = 0;
    //     childrenHeight = 0;
    //     childrenTop = 0;
    //     childrenLeft = 0;
    //   } else {
    //     const videoRatio = width / height;
    //     const containerRatio = usableSize.width / usableSize.height;

    //     if (videoRatio > containerRatio) {
    //       scale = usableSize.width / width;
    //       childrenWidth = usableSize.width;
    //       childrenHeight = height * scale;
    //       childrenTop = (usableSize.height - childrenHeight) / 2;
    //       childrenLeft = 0;
    //     } else {
    //       scale = usableSize.height / height;
    //       childrenWidth = width * scale;
    //       childrenHeight = usableSize.height;
    //       childrenTop = 0;
    //       childrenLeft = (usableSize.width - childrenWidth) / 2;
    //     }
    //   }

    //   return {
    //     scale,
    //     width: childrenWidth,
    //     height: childrenHeight,
    //     top: childrenTop,
    //     left: childrenLeft,
    //   };
    // }, [width, height, usableSize]);

    // const containerRef = useRef<HTMLDivElement | null>(null);
    // useImperativeHandle(
    //   ref,
    //   () => ({
    //     enterFullscreen() {
    //       containerRef.current!.requestFullscreen();
    //     },
    //   }),
    //   []
    // );

    type Size = {
      width?: number
      height?: number
    }

    const containerRef = useRef<HTMLDivElement>(null)
    const { width: containerWidth = 0, height: containerHeight = 0 } = useResizeObserver({
      ref: containerRef,
      box: "border-box",
    })

    // console.log("containerWidth: ", containerWidth)
    // console.log("containerHeight: ", containerHeight)

    const childrenStyle = useMemo(() => {
      let scale: number
      let childrenWidth: number
      let childrenHeight: number
      let childrenTop: number
      let childrenLeft: number

      if (width === 0 || containerWidth === 0) {
        scale = 1
        childrenWidth = 0
        childrenHeight = 0
        childrenTop = 0
        childrenLeft = 0
      } else {
        const videoRatio = width / height
        const containerRatio = containerWidth / containerHeight

        // if (videoRatio > containerRatio) {
        scale = containerWidth / width
        childrenWidth = containerWidth
        childrenHeight = height * scale
        childrenTop = 0//(containerHeight - childrenHeight) / 2
        childrenLeft = 0
        // } else {
        //   scale = containerHeight / height
        //   childrenWidth = width * scale
        //   childrenHeight = containerHeight
        //   childrenTop = 0
        //   childrenLeft = (containerWidth - childrenWidth) / 2
        // }
      }

      return {
        scale,
        width: childrenWidth,
        height: childrenHeight,
        top: childrenTop,
        left: childrenLeft,
      }
    }, [width, containerWidth, height, containerHeight])

    return (
      <div className="flex flex-col" style={{ position: "relative" }}>
        <div ref={containerRef} className="bg-gray-500" {...props}>
          {/* <ResizeObserver onResize={setContainerSize} /> */}

          <div
            className="absolute"
            // style={{
            //   transformOrigin: "top left",
            //   top: childrenStyle.top,
            //   left: childrenStyle.left,
            //   width,
            //   height,
            //   transform: `scale(${childrenStyle.scale})`,
            // }}
            style={{
              transformOrigin: "top left",
              top: childrenStyle.top,
              left: childrenStyle.left,
              width,
              height: 'auto',
              transform: `scale(${childrenStyle.scale})`,
            }}
          >
            {children}
          </div>

          {!!width && !!BottomElement && (
            <BottomElement
              className="absolute"
              style={{}}
            // style={{
            //   top: childrenStyle.top + childrenStyle.height,
            //   left: childrenStyle.left,
            //   width: childrenStyle.width,
            // }}
            >
              <></>
              {/* <ResizeObserver onResize={setBottomSize} /> */}
            </BottomElement>
          )}
        </div>
      </div>
    );
  }
);
