"use client";
import { Adb, AdbDaemonTransport } from "@yume-chan/adb";
import { AdbScrcpyClient } from "@yume-chan/adb-scrcpy";
import { ConsumableWritableStream, DecodeUtf8Stream, InspectStream, ReadableStream, WrapConsumableStream, WrapReadableStream, WrapWritableStream, WritableStreamDefaultController, pipeFrom } from "@yume-chan/stream-extra";
import { AdbPacketData, AdbPacketInit } from "@yume-chan/adb";
import { Consumable, ReadableWritablePair } from "@yume-chan/stream-extra";
import { useState, useEffect, useCallback, useMemo, use } from 'react';
import { AdbDaemonWebUsbConnection, AdbDaemonWebUsbDevice, AdbDaemonWebUsbDeviceManager, AdbDaemonWebUsbDeviceWatcher } from "@yume-chan/adb-daemon-webusb";
import { AdbDaemonDevice } from '@yume-chan/adb';
import AdbWebCredentialStore from "@yume-chan/adb-credential-web";
import { AdbServerClient } from "@yume-chan/adb";
// import { useAdbContext } from "@/contexts/adbContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ReadableStreamDefaultReader } from "stream/web";
import { GLOBAL_STATE } from "./global";
import Scrcpy from "./scrcpy";
import { observer } from "mobx-react-lite";
import { useBoolean } from "usehooks-ts";
import { connect } from "http2";
import { STATE } from "./state";

function Mirror({ standalone = false }: { standalone?: boolean }) {
  // const {
  //   adb,
  //   device,
  //   setAdb,
  //   setDevice,
  //   appendPacketLog,
  //   packetLog,
  //   showErrorDialog,
  //   errorDialogMessage,
  //   errorDialogVisible,
  // } = useAdbContext();

  const [selectedDevice, setSelectedDevice] = useState<AdbDaemonWebUsbDevice | undefined>(undefined);
  const [connecting, setConnecting] = useState(false);
  // const [Manager, setManager] = useState<AdbDaemonWebUsbDeviceManager>();
  const [USBDevices, setUSBDevices] = useState<AdbDaemonWebUsbDevice[]>([]);
  const [credentialStore, setCredentialStore] = useState<AdbWebCredentialStore>(new AdbWebCredentialStore());
  const [usbSupported, setUsbSupported] = useState(false);

  const updateUsbDeviceList = useCallback(async () => {
    const devices: AdbDaemonWebUsbDevice[] =
      await AdbDaemonWebUsbDeviceManager.BROWSER!.getDevices();
    setUSBDevices(devices);
    return devices;
  }, []);

  useEffect(() => {
    const supported = !!AdbDaemonWebUsbDeviceManager.BROWSER;
    setUsbSupported(supported);

    if (!supported) {
      alert("Your browser does not support WebUSB standard, which is required for this site to work.\n\nLatest version of Google Chrome, Microsoft Edge, or other Chromium-based browsers are required.");
      return;
    }
    updateUsbDeviceList();
    const watcher = new AdbDaemonWebUsbDeviceWatcher(async (serial?: string) => {
      const list = await updateUsbDeviceList();

      if (serial) {
        setUSBDevices([list.find((device) => device.serial === serial) as AdbDaemonWebUsbDevice]);
        return;
      }
    }, globalThis.navigator.usb);

    return () => watcher.dispose();
  }, []);


  const requestPermission = async () => {
    if (navigator) {
      const device: USBDevice = await navigator.usb.requestDevice({
        filters: [
          {
            classCode: 0xff,
            subclassCode: 0x42,
            protocolCode: 1,
          },
        ],
      });
      console.log("devices", device);


      getDevices();
    }
  };

  const getDevices = async () => {
    if (AdbDaemonWebUsbDeviceManager.BROWSER) {
      const devices: AdbDaemonWebUsbDevice[] = await AdbDaemonWebUsbDeviceManager.BROWSER.getDevices();
      console.log("devices", devices);
      if (!devices.length) {
        alert("No device connected");
        return;
      }

      setUSBDevices([devices[0]]);
    }
  };

  const connect = async (device: AdbDaemonWebUsbDevice) => {
    console.log("device", device.serial);

    if (!device || !credentialStore) return;

    setConnecting(true);

    let readable: ReadableStream<AdbPacketData>;
    let writable: WritableStream<Consumable<AdbPacketInit>>;
    let streams: ReadableWritablePair<AdbPacketData, Consumable<AdbPacketInit>>;

    try {
      streams = await device.connect();

      readable = streams.readable.pipeThrough(new InspectStream((packet) => {
        GLOBAL_STATE.appendLog("in", packet);
      }));
      writable = pipeFrom(
        streams.writable,
        new InspectStream((packet: Consumable<AdbPacketInit>) => {
          GLOBAL_STATE.appendLog("out", packet.value);
        })
      );
    } catch (e: any) {
      GLOBAL_STATE.showErrorDialog(e);
      setConnecting(false);
      return;
    }

    async function dispose() {
      // Adb won't close the streams,
      // so manually close them.
      try {
        readable.cancel();
      } catch { }
      try {
        await writable.close();
      } catch { }
      GLOBAL_STATE.setDevice(undefined, undefined);
    }

    // credentialStore.generateKey().then((key) => {
    //   console.log("key", key);
    // }
    // ).catch((e) => {
    //   console.error("Error generating key", e);
    // });

    try {
      const transport = await AdbDaemonTransport.authenticate({
        serial: device.serial,
        // @ts-ignore
        connection: {
          readable: readable,
          // @ts-ignore
          writable: writable,
        },
        credentialStore: credentialStore,
      });

      const client = new Adb(transport);

      client.disconnected.then(
        async () => {
          await dispose();
        },
        async (e) => {
          GLOBAL_STATE.showErrorDialog(e);
          await dispose();
        }
      );

      GLOBAL_STATE.setDevice(USBDevices[0], client);
    } catch (e) {
      console.error("Error authenticating", e);
      await dispose();
    } finally {
      setConnecting(false);
    }
  }

  const disconnect = useCallback(async () => {
    try {
      await GLOBAL_STATE.adb!.close();
    } catch (e: any) {
      GLOBAL_STATE.showErrorDialog(e);
    }
  }, []);

  const deviceList = useMemo(() => {
    return ([] as AdbDaemonWebUsbDevice[]).concat(USBDevices);
  }, [USBDevices]);

  const deviceOptions = useMemo(() => {
    return deviceList.map((device) => (
      {
        key: device.serial,
        text: `${device.serial} - ${device.name ? `(${device.name})` : ''}`,
        device: device,
      }
    ));
  }, [deviceList]);

  useEffect(() => {
    setSelectedDevice((old) => {
      if (old && !deviceList.find((d) => d.serial === old.serial)) {
        const current = deviceList.find((d) => d.serial === old.serial);
        if (current) {
          return current;
        }
      }
      return undefined;
    });

  }, [deviceList]);

  const DeviceInfo = useMemo(() => {
    if (!GLOBAL_STATE.adb) {
      return undefined;
    }

    return {
      productName: GLOBAL_STATE.adb.banner.product,
      modelName: GLOBAL_STATE.adb.banner.model,
      deviceName: GLOBAL_STATE.adb.banner.device,
      // battery: await adb.subprocess.spawnAndWaitLegacy("dumpsys battery").then((output) => {
      //   return output;
      // }),
    }
  }, [GLOBAL_STATE.adb]);

  const [BatteryLevel, setBatteryLevel] = useState<any>(undefined);



  const getBatteryInformation = useCallback(() => {
    if (!GLOBAL_STATE.adb) {
      console.log("No adb");
      return undefined;
    }

    return GLOBAL_STATE.adb.subprocess.spawnAndWaitLegacy("dumpsys battery").then((output) => {
      // use regex to parse output
      const level = output.match(/level: (\d+)/);
      const scale = output.match(/scale: (\d+)/);
      const status = output.match(/status: (\w+)/);
      const health = output.match(/health: (\w+)/);
      const temperature = output.match(/temperature: (\d+)/);

      const obj = {
        level: level ? parseInt(level[1]) : undefined,
        scale: scale ? parseInt(scale[1]) : undefined,
        status: status ? status[1] : undefined,
        health: health ? health[1] : undefined,
        temperature: temperature ? temperature[1] : undefined,
      }

      console.log("Battery", obj);
      setBatteryLevel(obj);
    });
  }, [GLOBAL_STATE.adb]);

  useEffect(() => {
    getBatteryInformation();
  }, [getBatteryInformation]);

  useEffect(() => {
    const pollBattery = setInterval(() => {
      getBatteryInformation();
    }, 10000);
    // every 60 seconds

    return () => clearInterval(pollBattery);

  }, []);

  const BatteryLevelComponent = () => {
    if (!BatteryLevel) {
      return null;
    }

    const getBatteryColor = () => {
      if (BatteryLevel.level / BatteryLevel.scale > 0.8) {
        return "bg-green-500";
      } else if (BatteryLevel.level / BatteryLevel.scale > 0.4) {
        return "bg-yellow-500";
      } else {
        return "bg-red-500";
      }
    }


    // visualize battery level with level/scale 
    return (
      <div className="flex items-center space-x-1 pt-1">
        <div className="flex w-10 h-5 items-center relative">
          <div className="border-gray-500 border-2 rounded-[3px] flex-1 h-full p-[1px]">
            <div className={`h-full rounded-[2px] ${getBatteryColor()}`} style={{ width: `${(BatteryLevel.level / BatteryLevel.scale) * 100}%` }}
            ></div>
          </div>
          <div className="bg-gray-500 rounded-r-md w-1 h-1/2"></div>
        </div>
        <div className="text-sm font-medium text-gray-800 whitespace-wrap">
          {Math.round(BatteryLevel.level / BatteryLevel.scale * 10000) / 100}%
        </div>
      </div>
    );
  }

  const { value: isMirrorOpen, toggle: toggleIsMirrorOpen } = useBoolean(standalone === true);
  const [isConnectDeviceModalOpen, setIsConnectDeviceModalOpen] = useState(false);

  useEffect(() => {
    //selectedDevice === d.device && GLOBAL_STATE.adb?.serial === d.device.serial ?
    console.log("selectedDevice", selectedDevice);
    console.log("GLOBAL_STATE.adb", GLOBAL_STATE.adb);

    if (selectedDevice === undefined && GLOBAL_STATE.adb?.serial === undefined && deviceOptions.length > 0 && isMirrorOpen) {
      setIsConnectDeviceModalOpen(true);
    } else {
      setIsConnectDeviceModalOpen(false);
    }

  }, [deviceOptions, isMirrorOpen, selectedDevice]);


  useEffect(() => {
    if (!STATE.running && selectedDevice) {
      STATE.start()

    }
  }, [getBatteryInformation, selectedDevice]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <div className="w-full items-center justify-between text-sm flex flex-col space-y-4 lg:flex-row lg:gap-x-4 lg:items-start lg:justify-between lg:space-y-0">
      <div className="items-start justify-between text-sm flex flex-col bg-white rounded-2xl px-8 py-4 shadow-xl w-full">
        <div className="text-lg font-medium text-gray-500 select-none cursor-pointer items-center flex w-full justify-between" onClick={() => { !standalone && toggleIsMirrorOpen() }}>
          Mirror Your Screen
          <Icon icon="ep:arrow-up-bold" className={`${isMirrorOpen ? "rotate-180" : "rotate-0"} transform duration-300`} />
        </div>
        <div className={`${isMirrorOpen ? "" : "max-h-0"} overflow-hidden w-full`}>
          <div>
            {usbSupported ?
              <div className="flex space-x-2 items-center my-4">
                <Icon icon="feather:check-circle" className="text-green-500 w-4 h-4" /><div className="text-xs">USB connections are <span className="font-semibold text-green-500">supported</span> by your browser</div>
              </div> :
              <div className="flex space-x-4 items-center w-full bg-red-100 p-2 pl-4 rounded-md">
                <Icon icon="feather:x-circle" className="text-red-500 w-8 h-8" />
                <div className="flex flex-col">
                  <div className="items-center text-base font-semibold">USB connections are <span className="font-semibold text-red-500">not supported</span> by your browser</div>
                  <div className="text-sm">Latest version of Google Chrome, Microsoft Edge, or other Chromium-based browsers are required for Screen Mirroring.</div>
                </div>
              </div>
            }
            {usbSupported && (
              <div className="flex flex-col gap-y-[15px] w-full">
                {/* <Icon icon="bi:headset-vr" className="w-8 h-8" /><Icon icon="bi:usb-plug-fill" className="w-6 h-6" /> */}
                <div className={`flex flex-col space-y-2 w-full ${STATE.running ? 'hidden' : 'block'}`}>
                  <div className="flex space-x-4 items-center">
                    <div className="font-semibold text-gray-500">1.</div>
                    <div className="flex items-center justify-center w-8 h-8"><Icon icon="bi:headset-vr" className="w-8 h-8 block" /></div>
                    <div className="">Power on your headset and connect the small end of the USB cable to the headset</div>
                    <div className="flex items-center justify-center w-8 h-8 p-1"><Icon icon="bi:usb-micro" className="w-8 h-8" /></div>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <div className="font-semibold text-gray-500">2.</div>
                    <div className="flex items-center justify-center w-8 h-8"><Icon icon="bi:usb-plug-fill" className="w-8 h-8" /></div>
                    <div className="">Connect the other end of the USB cable to your computer, laptop, or tablet</div>
                    <div className="flex items-center justify-center w-8 h-8"><Icon icon="bi:usb" className="w-8 h-8" /></div>
                  </div>

                  <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-between md:items-center w-full">
                    <div className="flex space-x-4 items-center">
                      <div className="font-semibold text-gray-500">3.</div><Icon icon="ph:cursor-click-fill" className="w-8 h-8" /><div className="">Click the <span className="font-medium">Add Headset</span> button and select the Headset (it may start with &quot;SX...&quot;) and click the <span className="font-medium">Connect</span> button</div>
                    </div>

                  </div>
                </div>
                <button className="px-4 py-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:opacity-50 font-semibold" onClick={requestPermission}>Add Headset</button>
                {/* <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-between md:items-center w-full">
                  <div className="flex space-x-4 items-center">
                    <div className="font-semibold text-gray-500">4.</div><Icon icon="ph:cursor-click-fill" className="w-8 h-8" /><div className="">The Headset should be listed below. Click the <span className="font-medium">Connect</span> button to start Screen Mirroring</div>
                  </div>
                </div> */}
                <div className="flex flex-col">
                  <div className="text-lg font-medium text-gray-500">
                    Device(s) Detected
                  </div>
                  {deviceOptions.length === 0 ? (
                    <div className={`flex-1 flex gap-x-[20px] items-center p-2 border border-gray-300 :outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent hover:bg-blue-100 hover:text-white cursor-pointer`}>
                      <div className="text-sm font-semibold flex justify-center items-center w-full py-3 text-gray-500">
                        No devices detected
                      </div>
                    </div>
                  ) : (

                    <div className="flex flex-col w-full space-y-2">
                      {deviceOptions.map((d) => (
                        <div
                          key={d.key}
                          className={`flex-1 flex gap-x-[20px] items-center p-2 border border-gray-300 :outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                        >
                          {/* <div className="flex flex-col space-y-0">
                            <div className="text-xs font-medium text-gray-500">
                              Device Model
                            </div>
                            {DeviceInfo && (
                              <div className="text-xl font-medium text-gray-800 whitespace-wrap w-full">{DeviceInfo.modelName}</div>
                            )}
                          </div> */}
                          {
                            GLOBAL_STATE.adb?.serial === d.device.serial &&
                            <div className="flex flex-col justify-start">
                              <div className="text-[0.7rem] font-medium text-gray-800">
                                Device Model
                              </div>
                              <div className="text-sm font-semibold text-gray-500">
                                {DeviceInfo ? (
                                  DeviceInfo.modelName
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          }
                          <div className="flex-1 flex items-center justify-center space-x-2">
                            {DeviceLabel({ device: d.device })}
                          </div>
                          {
                            GLOBAL_STATE.adb?.serial === d.device.serial &&
                            <>
                              <div className="flex flex-col justify-start">
                                <div className="text-[0.7rem] font-medium text-gray-800">
                                  Battery Level
                                </div>
                                <div className="text-lg font-medium text-gray-800 whitespace-wrap w-full"><BatteryLevelComponent /></div>
                              </div>

                              <button
                                className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 font-semibold"
                                onClick={() => {
                                  disconnect()
                                  setSelectedDevice(undefined);
                                }}
                              >
                                Disconnect
                              </button>
                            </>
                          }
                          {GLOBAL_STATE.adb?.serial === undefined && (
                            <button
                              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 font-semibold"
                              onClick={() => connect(d.device)}
                            >
                              Connect
                            </button>
                          )}

                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={`w-full flex justify-center pointer-events-none ${isFullScreen ? 'fixed inset-0 z-50 aspect-video' : 'relative'}`}>
                  <div className="relative aspect-w-16 aspect-h-9 max-h-screen">
                    <Scrcpy />
                  </div>
                </div>
                {isFullScreen ? (
                  <>
                    <div className="fixed z-[49] inset-0 m-0 p-0 bg-black bg-opacity-50" />

                    <div className="fixed z-[51] top-0 right-0">
                      <button
                        className="px-4 py-2 text-white border-2 border-white bg-transparent rounded-md hover:bg-white hover:text-black font-semibold"
                        onClick={() => setIsFullScreen(false)}
                      >
                        Exit Full Screen
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col space-y-2">
                    <button
                      className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 font-semibold"
                      onClick={() => setIsFullScreen(true)}
                    >
                      Full Screen
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isConnectDeviceModalOpen && (
        <ConnectDeviceModal
          deviceOptions={deviceOptions}
          onClose={() => setIsConnectDeviceModalOpen(false)}
          connect={connect}

        />
      )}
    </div>

  );
}

// if a device is detected in the deviceOptions list, show a modal that if the user wants to connect the device
const ConnectDeviceModal = ({
  connect,
  deviceOptions,
  onClose
}: {
  connect: (device: AdbDaemonWebUsbDevice) => void,
  deviceOptions: { key: string, text: string, device: AdbDaemonWebUsbDevice }[],
  onClose: () => void
}) => {

  const [selectedDeviceOption, setSelectedDeviceOption] = useState<{ key: string, text: string, device: AdbDaemonWebUsbDevice } | undefined>(deviceOptions[0]);

  console.log("deviceOptions", deviceOptions);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <>
      <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center`} onClick={onClose} />
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="flex flex-col bg-white rounded-md p-4 space-y-4">
          <div className="text-lg font-medium text-gray-500">
            Connect Device?
          </div>
          <div className="text-sm text-gray-800">
            Device(s) detected. Would you like to connect the device and start screen mirroring?
          </div>
          <div className="flex w-full space-x-4 items-center">
            <div className="text-sm text-gray-800">
              Selected Device:
            </div>
            {deviceOptions.length > 0 && (
              <div className="relative flex-1">
                <div
                  className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  onClick={() => setIsDropdownOpen((old) => !old)}

                >
                  <div className="flex items-center justify-between cursor-pointer">
                    <div>
                      {selectedDeviceOption ? DeviceLabel({ device: selectedDeviceOption.device }) : 'Select a device'}
                    </div>
                    <div className="flex items-center justify-center">
                      <Icon icon="bi:chevron-down" className="w-4 h-4" />
                    </div>
                  </div>
                </div>
                <div className={`absolute z-50 w-full bg-white border border-gray-300 rounded-md shadow-lg ${isDropdownOpen ? 'block' : 'hidden'}`}>
                  {deviceOptions.map((d) => (
                    <div
                      key={d.key}
                      className={`flex-1 p-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent hover:bg-blue-100 hover:text-white cursor-pointer`}
                      onClick={() => {
                        setSelectedDeviceOption(d);
                        setIsDropdownOpen(false);
                      }}>
                      {DeviceLabel({ device: d.device })}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* {deviceOptions.length === 1 && selectedDeviceOption && (
              <div key={selectedDeviceOption.key} className={`flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"`}>
                <label>{DeviceLabel({ device: deviceOptions[0].device })}</label>
              </div>
            )} */}
            {deviceOptions.length === 0 && (
              <div className={`flex-1 text-red-600 bg-red-100 font-semibold p-2 border border-red-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"`}>
                <label>Error: No device detected</label>
              </div>
            )}
          </div>
          <div className="flex space-x-4 items-center justify-end">

            <button
              className="px-4 py-2  bg-gray-100 rounded-md hover:bg-gray-300 font-semibold"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 font-semibold"
              onClick={() => {
                if (selectedDeviceOption) {
                  connect(selectedDeviceOption.device);
                  onClose();
                }
              }}
            >
              Connect
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const DeviceLabel = ({ device }: { device: AdbDaemonWebUsbDevice }) => {
  return (
    <div className="flex-1 flex items-center justify-evenly space-x-2">
      <div className="flex flex-col">
        <div className="text-[0.7rem] font-medium text-gray-800">
          Serial
        </div>
        <div className="text-sm font-semibold text-gray-500">
          {device.serial}
        </div>
      </div>

      <div className="flex flex-col">
        <div className="text-[0.7rem] font-medium text-gray-800">
          Name
        </div>
        <div className="text-sm font-semibold text-gray-500">
          {device.name}
        </div>
      </div>
    </div>
  );
}


export default observer(Mirror);