import { WebCodecsDecoder } from "@yume-chan/scrcpy-decoder-webcodecs";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { NextPage } from "next";
import Head from "next/head";
import { CSSProperties, ComponentType, HTMLAttributes, KeyboardEvent, ReactNode, forwardRef, useEffect, useMemo, useRef, useState } from "react";
// import {
//   NavigationBar,
//   SETTING_DEFINITIONS,
//   SETTING_STATE,
//   STATE,
//   ScrcpyCommandBar,
//   SettingItem,
//   VideoContainer,
// } from "../components/scrcpy";
import { SETTING_DEFINITIONS, SETTING_STATE, SettingItem } from "./settings";
import { STATE } from "./state";
import { VideoContainer } from "./video-container";
import { DeviceView } from "./device-view";

import { useLocalStorage, useResizeObserver, useWindowSize } from "usehooks-ts";
import { GLOBAL_STATE } from "./global";
import { formatSpeed } from "./utils";
import Link from "next/link";
import { ST } from "next/dist/shared/lib/utils";

const ConnectingDialog = observer(() => {

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <>
      {/* <div id="layerHost" className="absolute inset-0 pointer-events-none m-0" /> */}
      <div
        hidden={!STATE.connecting}
        className="flex items-center justify-center hidden"
      // className="z-50 absolute inset-0 flex items-center justify-center"
      >
        <div className="text-2xl font-bold">Connecting...</div>
        <div className="gap-4 flex flex-col items-center justify-center">
          <div>Downloading scrcpy server...</div>
          <div className="relative w-64 h-1 bg-gray-300 rounded-md">
            <div
              className="absolute h-full bg-blue-500 rounded-md"
              style={{
                width: `${STATE.serverTotalSize ? (STATE.serverDownloadedSize / STATE.serverTotalSize) * 100 : 0}%`,
              }}
            />
          </div>

          <div className="text-sm">
            {formatSpeed(
              STATE.debouncedServerDownloadedSize,
              STATE.serverTotalSize,
              STATE.serverDownloadSpeed
            )}
          </div>

          <div>Pushing scrcpy server to device...</div>
          <div className="relative w-64 h-1 bg-gray-300 rounded-md">
            <div
              className="absolute h-full bg-blue-500 rounded-md"
              style={{
                width: `${STATE.serverTotalSize ? (STATE.serverUploadedSize / STATE.serverTotalSize) * 100 : 0}%`,
              }}
            />
          </div>

          <div className="text-sm">
            {formatSpeed(
              STATE.debouncedServerUploadedSize,
              STATE.serverTotalSize,
              STATE.serverUploadSpeed
            )}

          </div>

          <div>Starting scrcpy server on device...</div>

          <div className="relative w-64 h-1 bg-gray-300 rounded-md">
            <div
              className="absolute h-full bg-blue-500 rounded-md"
              style={{
                width: `${STATE.serverTotalSize ? (STATE.serverUploadedSize / STATE.serverTotalSize) * 100 : 0}%`,
              }}
            />
          </div>

        </div>
      </div>
    </>
  );
});

async function handleKeyEvent(e: KeyboardEvent<HTMLDivElement>) {
  if (!STATE.client) {
    return;
  }

  e.preventDefault();
  e.stopPropagation();

  const { type, code } = e;
  STATE.keyboard![type === "keydown" ? "down" : "up"](code);
}

function handleBlur() {
  if (!STATE.client) {
    return;
  }

  STATE.keyboard?.reset();
}

const FullscreenHint = observer(function FullscreenHint({
  keyboardLockEnabled,
}: {
  keyboardLockEnabled: boolean;
}) {

  const [hintHidden, setHintHidden] = useLocalStorage<`${boolean}`>(
    "scrcpy-hint-hidden",
    "false"
  );

  if (!keyboardLockEnabled || !STATE.isFullScreen || hintHidden === "true") {
    return null;
  }

  return (
    <div className="flex text-white gap-x-[12px] px-2 py-5">
      <div>{GLOBAL_STATE.device?.serial}</div>
      <div>FPS: {STATE.fps}</div>

      <div className="flex-grow" />

      <div>Press and hold ESC to exit full screen</div>

      <button onClick={() => setHintHidden("true")}>
        {`Don't show again`}
      </button>
    </div>
  );
});

const Scrcpy = () => {
  // const classes = useClasses();

  useEffect(() => {
    // Detect WebCodecs support at client side
    if (
      SETTING_STATE.decoders.length === 1 &&
      WebCodecsDecoder.isSupported()
    ) {
      runInAction(() => {
        SETTING_STATE.decoders.unshift({
          key: "webcodecs",
          name: "WebCodecs",
          Constructor: WebCodecsDecoder,
        });
      });
    }
  }, []);

  const [keyboardLockEnabled, setKeyboardLockEnabled] = useState(false);
  useEffect(() => {
    if (!("keyboard" in navigator)) {
      return;
    }

    // Keyboard Lock is only effective in fullscreen mode,
    // but the `lock` method can be called at any time.

    // // @ts-expect-error
    // navigator.keyboard.lock();
    // setKeyboardLockEnabled(true);

    // return () => {
    //   // @ts-expect-error
    //   navigator.keyboard.unlock();
    // };
  }, []);

  useEffect(() => {
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  // const [running, setRunning] = useState(false);

  // useEffect(() => {
  //   console.log("STATE.running: ", STATE.running);
  //   if (STATE.running) {
  //     setRunning(true);
  //   } else {
  //     setRunning(false);
  //   }
  // }, [STATE.running]);

  // const containerWidth = useMemo(() => {
  //   if (STATE.isFullScreen) {
  //     return window.innerWidth;
  //   }

  //   return STATE.width;
  // }, [STATE.isFullScreen, STATE.width]);

  // const containerHeight = useMemo(() => {
  //   // if (STATE.isFullScreen) {
  //   //   S
  //   // }

  //   return STATE.height;
  // }, []);


  type Size = {
    width?: number
    height?: number
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const { width: containerWidth } = useResizeObserver({
    ref: containerRef,
    box: "border-box",
  })

  console.log("pContainerWidth: ", containerWidth)
  // console.log("containerHeight: ", containerHeight)

  const parentContainerWidth = useMemo(() => {

    return containerWidth;
  }, [STATE.isFullScreen, containerWidth]);

  // console.log("parentContainerWidth: ", parentContainerWidth)

  const [isDeviceConnected, setIsDeviceConnected] = useState(false);

  useEffect(() => {
    if (GLOBAL_STATE.adb?.serial) {
      setIsDeviceConnected(true);
      STATE.start();
    } else {
      setIsDeviceConnected(false);
    }
  }, [GLOBAL_STATE.adb?.serial]);


  const windowSize = useWindowSize();
  const size = useMemo<Size>(() => {
    // limit the size to within the bounds of the window and aspect ratio of the device
    const width = Math.min(windowSize.width, windowSize.height * (STATE.width / STATE.height));
    const height = width * (STATE.height / STATE.width);
    return { width, height };
  }, [windowSize, STATE.width, STATE.height]);

  return (
    <div className="">
      <div className="flex flex-col w-full " ref={containerRef}>
        {
          STATE.running ? (
            <>
              {/* <ScrcpyCommandBar /> */}

              {/* <Stack horizontal grow styles={{ root: { height: 0 } }}>*/}
              <div className="flex space-x-4 w-full">
                <div
                  ref={STATE.setFullScreenContainer}
                  className="flex flex-col bg-black focus-visible:outline-none"
                  tabIndex={0}
                  style={{
                    width: size.width,
                    height: size.height,
                  }}
                // onKeyDown={handleKeyEvent}
                // onKeyUp={handleKeyEvent}
                >

                  <FullscreenHint
                    keyboardLockEnabled={keyboardLockEnabled}
                  />

                  <DeviceView
                    width={STATE.rotatedWidth}
                    height={STATE.rotatedHeight}
                  // BottomElement={NavigationBar}
                  >
                    <VideoContainer />
                  </DeviceView>
                </div>

                <div
                  style={{
                    padding: 12,
                    overflow: "hidden auto",
                    display: STATE.logVisible ? "block" : "none",
                    width: 500,
                    fontFamily: "monospace",
                    overflowY: "auto",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                >
                  {STATE.log.map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </div>

                {/* <DemoModePanel
              style={{
                display: STATE.demoModeVisible
                  ? "block"
                  : "none",
              }}
            /> */}
              </div>
            </>
          ) : (
            <>
              {/* <div>
                <Link
                  href="https://github.com/Genymobile/scrcpy"
                >
                  Scrcpy
                </Link>{" "}
                can mirror device display and audio with low latency and
                control the device, all without root access.
              </div>
              <div>
                This is a TypeScript re-implementation of the client
                part. Paired with official pre-built server binary.
              </div> */}

              <div className="flex justify-center p-10">
                {/* <button
                  className="p-2 bg-blue-500 text-white rounded-md font-medium"
                  onClick={STATE.start}
                // disabled={!GLOBAL_STATE.adb}
                >
                  Start Mirroring
                </button> */}
                {/* <PrimaryButton
              text="Start"
              disabled={!GLOBAL_STATE.adb}
              onClick={STATE.start}
            /> */}
              </div>

              {SETTING_DEFINITIONS.get().map((definition) => (
                <SettingItem
                  key={definition.key}
                  definition={definition}
                  value={
                    (SETTING_STATE[definition.group] as any)[
                    definition.key
                    ]
                  }
                  onChange={action(
                    (definition, value) =>
                    ((SETTING_STATE[definition.group] as any)[
                      definition.key
                    ] = value)
                  )}
                />
              ))}

              <ConnectingDialog />
            </>
          )
        }
      </div >
    </div>
  );
};

export default observer(Scrcpy);