"use client";
import { createContext, useContext, useState, useMemo, useEffect } from "react";
import { Adb, AdbDaemonDevice, AdbPacketData } from "@yume-chan/adb";

export type PacketLogItemDirection = "in" | "out";

export interface PacketLogItem extends AdbPacketData {
  direction: PacketLogItemDirection;
  timestamp?: Date;
  commandString?: string;
  arg0String?: string;
  arg1String?: string;
  payloadString?: string;
}

export interface AdbContextState {
  adb: Adb | undefined;
  device: AdbDaemonDevice | undefined;
  setAdb: (adb: Adb | undefined) => void;
  setDevice: (device: AdbDaemonDevice | undefined) => void;
  packetLog: PacketLogItem[];
  appendPacketLog: (direction: PacketLogItemDirection, packet: AdbPacketData) => void;
  errorDialogVisible: boolean;
  errorDialogMessage: string;
  showErrorDialog: (message: string) => void;
  hideErrorDialog: () => void;
  clearPacketLog: () => void;
}

export const AdbContext = createContext<AdbContextState | null>({
  adb: undefined,
  device: undefined,
  setAdb: () => { },
  setDevice: () => { },
  packetLog: [],
  appendPacketLog: () => { },
  errorDialogVisible: false,
  errorDialogMessage: "",
  showErrorDialog: () => { },
  hideErrorDialog: () => { },
  clearPacketLog: () => { },
});

export const useAdbContext = () => useContext(AdbContext)!;

export function AdbContextProvider(props: Readonly<{
  children: React.ReactNode;
}>) {
  const [adb, setAdb] = useState<Adb | undefined>(undefined);
  const [device, setDevice] = useState<AdbDaemonDevice | undefined>(undefined);
  const [packetLog, setPacketLog] = useState<PacketLogItem[]>([]);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");

  const state = useMemo(() => ({
    adb,
    device,
    setAdb,
    setDevice,
    packetLog,
    appendPacketLog: (direction: PacketLogItemDirection, packet: AdbPacketData) => {
      setPacketLog((log) => [
        ...log,
        {
          ...packet,
          direction,
          timestamp: new Date(),
          payload: packet.payload.slice(),
        },
      ]);
    },
    errorDialogVisible,
    errorDialogMessage,
    showErrorDialog: (message: string) => {
      setErrorDialogMessage(message);
      setErrorDialogVisible(true);
    },
    hideErrorDialog: () => setErrorDialogVisible(false),
    clearPacketLog: () => setPacketLog([]),
  }), [adb, device, errorDialogMessage, errorDialogVisible, packetLog]);

  return (
    <AdbContext.Provider value={state}>
      {props.children}
    </AdbContext.Provider>
  );
}

export default AdbContext;