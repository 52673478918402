import { AdbSyncError } from "@yume-chan/adb";
import { AdbScrcpyClient, AdbScrcpyOptionsLatest } from "@yume-chan/adb-scrcpy";
import { VERSION } from "@yume-chan/fetch-scrcpy-server";
import {
  CodecOptions,
  DEFAULT_SERVER_PATH,
  ScrcpyDisplay,
  ScrcpyEncoder,
  ScrcpyLogLevel,
  ScrcpyOptionsInitLatest,
  ScrcpyOptionsLatest,
  ScrcpyVideoOrientation,
} from "@yume-chan/scrcpy";
import {
  ScrcpyVideoDecoderConstructor,
  TinyH264Decoder,
} from "@yume-chan/scrcpy-decoder-tinyh264";
import { ConcatStringStream, DecodeUtf8Stream } from "@yume-chan/stream-extra";
import {
  autorun,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { observer } from "mobx-react-lite";
import { GLOBAL_STATE } from "./global";
import { STATE } from "./state";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";

export type Settings = ScrcpyOptionsInitLatest;

export interface ClientSettings {
  turnScreenOff?: boolean;
  decoder?: string;
  ignoreDecoderCodecArgs?: boolean;
}

export type SettingKeys = keyof (Settings & ClientSettings);

export interface SettingDefinitionBase {
  group: "settings" | "clientSettings";
  key: SettingKeys;
  type: string;
  label: string;
  labelExtra?: JSX.Element;
  description?: string;
}

export interface TextSettingDefinition extends SettingDefinitionBase {
  type: "text";
  placeholder?: string;
}

export interface DropdownSettingDefinition extends SettingDefinitionBase {
  type: "dropdown";
  placeholder?: string;
  options: { key: string | number; id?: string; text: string; title?: string; data?: any }[];
}

export interface ToggleSettingDefinition extends SettingDefinitionBase {
  type: "toggle";
  disabled?: boolean;
}

export interface NumberSettingDefinition extends SettingDefinitionBase {
  type: "number";
  min?: number;
  max?: number;
  step?: number;
}

export type SettingDefinition =
  | TextSettingDefinition
  | DropdownSettingDefinition
  | ToggleSettingDefinition
  | NumberSettingDefinition;

interface SettingItemProps {
  definition: SettingDefinition;
  value: any;
  onChange: (definition: SettingDefinition, value: any) => void;
}

export const Tooltip = (props: { content: string; children: JSX.Element }) => {
  return (
    <div title={props.content} className="group relative">
      {props.children}
      <div className="hidden group-hover:block absolute z-10 p-2 bg-white border border-gray-300 rounded-lg shadow-md">
        {props.content}
      </div>
    </div>
  );
};

export const SettingItem = observer(function SettingItem({
  definition,
  value,
  onChange,
}: SettingItemProps) {

  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);


  let label: JSX.Element = (
    <div className="flex items-center">
      <span>{definition.label}</span>
      {!!definition.description && (
        <Tooltip content={definition.description}>
          <Icon icon="feather:info" className="w-4 h-4 ml-2" />
        </Tooltip>
      )}
      {definition.labelExtra}
    </div>
  );

  switch (definition.type) {
    case "text":
      return (
        <div className="hidden items-center">
          <label className="w-1/3">{label}</label>
          <input
            className="w-2/3"
            placeholder={definition.placeholder}
            value={val}
            onChange={(e) => onChange(definition, e.target.value)}
          />
        </div>
      );
    case "dropdown":
      return (
        <div className="hidden items-center">
          <label className="w-1/3">{label}</label>
          <select
            className="w-2/3"
            value={val}
            onChange={(e) => onChange(definition, e.target.value)}
          >
            {definition.options.map((item) => (
              <option key={item.key} value={item.key}>
                {item.text}
              </option>
            ))}
          </select>
        </div>
      );
    case "toggle":
      return (
        <div className="hidden items-center">
          <label className="w-1/3">{label}</label>
          {/* <input
            className="w-2/3"
            type="checkbox"
            checked={val}
            disabled={definition.disabled}
            onChange={(e) => onChange(definition, e.target.checked)}
          /> */}

          <select
            className="w-2/3"
            value={val}
            onChange={(e) => onChange(definition, e.target.value)}
          >
            <option key="true" value="true">
              True
            </option>
            <option key="false" value="false">
              False
            </option>
          </select>
        </div>
      );
    case "number":
      return (
        <div className="hidden items-center">
          <label className="w-1/3">{label}</label>
          <input
            className="w-2/3"
            type="number"
            min={definition.min}
            max={definition.max}
            step={definition.step}
            value={val}
            onChange={(e) => onChange(definition, Number.parseInt(e.target.value, 10))}
          />
        </div>
      );
  }
});

export interface DecoderDefinition {
  key: string;
  name: string;
  Constructor: ScrcpyVideoDecoderConstructor;
}

const DEFAULT_SETTINGS = {
  maxSize: 1080,
  videoBitRate: 4_000_000,
  videoCodec: "h264",
  lockVideoOrientation: ScrcpyVideoOrientation.Unlocked,
  displayId: 0,
  crop: "",
  powerOn: true,
  audio: true,
  audioCodec: "aac",
} as Settings;

export const SETTING_STATE = makeAutoObservable(
  {
    displays: [] as ScrcpyDisplay[],
    encoders: [] as ScrcpyEncoder[],
    decoders: [
      {
        key: "tinyh264",
        name: "TinyH264 (Software)",
        Constructor: TinyH264Decoder,
      },
    ] as DecoderDefinition[],

    settings: DEFAULT_SETTINGS,

    clientSettings: {} as ClientSettings,
  },
  {
    decoders: observable.shallow,
    settings: observable.deep,
    clientSettings: observable.deep,
  }
);

export const SCRCPY_SETTINGS_FILENAME = "/data/local/tmp/.tango.json";

autorun(() => {
  if (GLOBAL_STATE.adb) {
    (async () => {
      const sync = await GLOBAL_STATE.adb!.sync();
      try {
        const content = await sync
          .read(SCRCPY_SETTINGS_FILENAME)
          .pipeThrough(new DecodeUtf8Stream())
          .pipeThrough(new ConcatStringStream());
        const settings = JSON.parse(content);
        settings.settings.videoCodecOptions = new CodecOptions(
          settings.settings.videoCodecOptions,
        );
        settings.settings.audioCodecOptions = new CodecOptions(
          settings.settings.audioCodecOptions,
        );
        runInAction(() => {
          SETTING_STATE.settings = {
            ...DEFAULT_SETTINGS,
            ...settings.settings,
          };
          SETTING_STATE.clientSettings = settings.clientSettings;
        });
      } catch (e) {
        if (!(e instanceof AdbSyncError)) {
          throw e;
        }
      } finally {
        await sync.dispose();
      }
    })();

    runInAction(() => {
      SETTING_STATE.encoders = [];
      SETTING_STATE.displays = [];
      SETTING_STATE.settings.displayId = undefined;
    });
  }
});

autorun(() => {
  SETTING_STATE.clientSettings.decoder = SETTING_STATE.decoders[0].key;
});

export const SETTING_DEFINITIONS = computed(() => {
  const result: SettingDefinition[] = [];

  result.push(
    {
      group: "settings",
      key: "powerOn",
      type: "toggle",
      label: "Wake device up on start",
    },
    {
      group: "clientSettings",
      key: "turnScreenOff",
      type: "toggle",
      label: "Turn screen off during mirroring",
    },
    {
      group: "settings",
      key: "stayAwake",
      type: "toggle",
      label: "Stay awake during mirroring (if plugged in)",
    },
    {
      group: "settings",
      key: "powerOffOnClose",
      type: "toggle",
      label: "Turn device off on stop",
    }
  );

  result.push({
    group: "settings",
    key: "displayId",
    type: "dropdown",
    label: "Display",
    placeholder: "Press refresh to update available displays",
    labelExtra: (
      <button
        disabled={!GLOBAL_STATE.adb}
        onClick={async () => {
          try {
            await STATE.pushServer();

            const displays = await AdbScrcpyClient.getDisplays(
              GLOBAL_STATE.adb!,
              DEFAULT_SERVER_PATH,
              VERSION,
              new AdbScrcpyOptionsLatest(
                new ScrcpyOptionsLatest({
                  logLevel: ScrcpyLogLevel.Debug,
                })
              )
            );

            runInAction(() => {
              SETTING_STATE.displays = displays;
              if (
                !SETTING_STATE.settings.displayId ||
                !SETTING_STATE.displays.some(
                  (x) =>
                    x.id ===
                    SETTING_STATE.settings.displayId
                )
              ) {
                SETTING_STATE.settings.displayId =
                  SETTING_STATE.displays[0]?.id;
              }
            });
          } catch (e: any) {
            GLOBAL_STATE.showErrorDialog(e);
          }
        }}
      >
        <Icon icon="feather:refresh-cw" className="w-4 h-4" />
      </button>
    ),
    options: SETTING_STATE.displays.map((item) => ({
      key: item.id,
      text: `${item.id}${item.resolution ? ` (${item.resolution})` : ""}`,
    })),
  });

  result.push({
    group: "settings",
    key: "crop",
    type: "text",
    label: "Crop",
    placeholder: "W:H:X:Y",
  });

  result.push(
    {
      group: "settings",
      key: "maxSize",
      type: "number",
      label: "Max Resolution (longer side, 0 = unlimited)",
      min: 0,
      max: 2560,
      step: 50,
    },
    {
      group: "settings",
      key: "videoBitRate",
      type: "number",
      label: "Max Video Bitrate (bps)",
      min: 100,
      max: 100_000_000,
      step: 100,
    },
    {
      group: "settings",
      key: "videoCodec",
      type: "dropdown",
      label: "Video Codec",
      options: [
        {
          key: "h264",
          text: "H.264",
        },
        {
          key: "h265",
          text: "H.265",
        },
      ],
    },
    {
      group: "settings",
      key: "videoEncoder",
      type: "dropdown",
      label: "Video Encoder",
      placeholder:
        SETTING_STATE.encoders.length === 0
          ? "Press refresh button to update encoder list"
          : "(default)",
      labelExtra: (
        <button
          disabled={!GLOBAL_STATE.adb}
          onClick={async () => {
            try {
              await STATE.pushServer();

              const encoders = await AdbScrcpyClient.getEncoders(
                GLOBAL_STATE.adb!,
                DEFAULT_SERVER_PATH,
                VERSION,
                new AdbScrcpyOptionsLatest(
                  new ScrcpyOptionsLatest({
                    logLevel: ScrcpyLogLevel.Debug,
                  })
                )
              );

              runInAction(() => {
                SETTING_STATE.encoders = encoders;
              });
            } catch (e: any) {
              GLOBAL_STATE.showErrorDialog(e);
            }
          }}
        >
          <Icon icon="feather:refresh-cw" className="w-4 h-4" />
        </button>
      ),
      options: SETTING_STATE.encoders
        .filter(
          (item) =>
            item.type === "video" &&
            (!item.codec ||
              item.codec === SETTING_STATE.settings.videoCodec!)
        )
        .map((item) => ({
          key: item.name,
          text: item.name,
        })),
    }
  );

  result.push({
    group: "settings",
    key: "lockVideoOrientation",
    type: "dropdown",
    label: "Lock Video Orientation",
    options: [
      {
        key: ScrcpyVideoOrientation.Unlocked,
        text: "Unlocked",
      },
      {
        key: ScrcpyVideoOrientation.Initial,
        text: "Current",
      },
      {
        key: ScrcpyVideoOrientation.Portrait,
        text: "Portrait",
      },
      {
        key: ScrcpyVideoOrientation.Landscape,
        text: "Landscape",
      },
      {
        key: ScrcpyVideoOrientation.PortraitFlipped,
        text: "Portrait (Flipped)",
      },
      {
        key: ScrcpyVideoOrientation.LandscapeFlipped,
        text: "Landscape (Flipped)",
      },
    ],
  });

  if (SETTING_STATE.decoders.length > 1) {
    result.push({
      group: "clientSettings",
      key: "decoder",
      type: "dropdown",
      label: "Video Decoder",
      options: SETTING_STATE.decoders.map((item) => ({
        key: item.key,
        text: item.name,
        data: item,
      })),
    });
  }

  result.push({
    group: "clientSettings",
    key: "ignoreDecoderCodecArgs",
    type: "toggle",
    label: `Ignore video decoder's codec options`,
    description: `Some decoders don't support all H.264 profile/levels, so they request the device to encode at their highest-supported codec. However, some super old devices may not support that codec so their encoders will fail to start. Use this option to let device choose the codec to be used.`,
  });

  result.push(
    {
      group: "settings",
      key: "audio",
      type: "toggle",
      label: "Forward Audio (Requires Android 11)",
    },
    {
      group: "settings",
      key: "audioCodec",
      type: "dropdown",
      label: "Audio Codec",
      options: [
        {
          key: "raw",
          text: "Raw",
        },
        {
          key: "aac",
          text: "AAC",
        },
        {
          key: "opus",
          text: "Opus",
        },
      ],
    },
    {
      group: "settings",
      key: "audioEncoder",
      type: "dropdown",
      placeholder:
        SETTING_STATE.encoders.length === 0
          ? "Press refresh button to update encoder list"
          : "(default)",
      label: "Audio Encoder",
      labelExtra: (
        <button
          disabled={!GLOBAL_STATE.adb}
          onClick={async () => {
            try {
              await STATE.pushServer();

              const encoders = await AdbScrcpyClient.getEncoders(
                GLOBAL_STATE.adb!,
                DEFAULT_SERVER_PATH,
                VERSION,
                new AdbScrcpyOptionsLatest(
                  new ScrcpyOptionsLatest({
                    logLevel: ScrcpyLogLevel.Debug,
                  })
                )
              );

              runInAction(() => {
                SETTING_STATE.encoders = encoders;
              });
            } catch (e: any) {
              GLOBAL_STATE.showErrorDialog(e);
            }
          }}
        >
          <Icon icon="feather:refresh-cw" className="w-4 h-4" />
        </button>
      ),
      options: SETTING_STATE.encoders
        .filter(
          (x) =>
            x.type === "audio" &&
            x.codec === SETTING_STATE.settings.audioCodec
        )
        .map((item) => ({
          key: item.name,
          text: item.name,
        })),
    }
  );

  return result;
});

autorun(() => {
  if (SETTING_STATE.encoders.length === 0) {
    SETTING_STATE.settings.videoEncoder = "";
    SETTING_STATE.settings.audioEncoder = "";
    return;
  }

  const encodersForCurrentVideoCodec = SETTING_STATE.encoders.filter(
    (item) =>
      item.type === "video" &&
      item.codec === SETTING_STATE.settings.videoCodec
  );
  if (
    SETTING_STATE.settings.videoEncoder &&
    encodersForCurrentVideoCodec.every(
      (item) => item.name !== SETTING_STATE.settings.videoEncoder
    )
  ) {
    SETTING_STATE.settings.videoEncoder = "";
  }

  const encodersForCurrentAudioCodec = SETTING_STATE.encoders.filter(
    (item) =>
      item.type === "audio" &&
      item.codec === SETTING_STATE.settings.audioCodec
  );
  if (
    SETTING_STATE.settings.audioEncoder &&
    encodersForCurrentAudioCodec.every(
      (item) => item.name !== SETTING_STATE.settings.audioEncoder
    )
  ) {
    SETTING_STATE.settings.audioEncoder = "";
  }
});
